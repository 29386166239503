<svg
	width="100%"
	height="100%"
	viewBox="0 0 2466 1644"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<g id="Group 1">
		<path
			id="Vector 1"
			d="M0 0V1559.97C319.858 1742.93 801.245 1608.12 990.527 1338.5C1223.75 1006.28 828.221 802.349 1300.07 457.4C1609.61 231.107 2084.23 457.4 2466 0H0Z"
			fill="#1F3147"
		/>
		<g id="character 14 2" clip-path="url(#clip0)">
			<g id="Mask Group">
				<mask
					id="mask0"
					mask-type="alpha"
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="2466"
					height="1644"
				>
					<path
						id="Vector 1_2"
						d="M0 0V1559.97C319.858 1742.93 801.245 1608.12 990.527 1338.5C1223.75 1006.28 828.221 802.349 1300.07 457.4C1609.61 231.107 2084.23 457.4 2466 0H0Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask0)">
					<g id="Layer 2">
						<g id="&#208;&#161;&#208;&#187;&#208;&#190;&#208;&#185; 1">
							<g id="2">
								<g id="CHAIR">
									<path
										id="Vector"
										d="M474.71 1135.28C474.71 1140.58 476.817 1145.67 480.568 1149.42C484.319 1153.17 489.406 1155.28 494.71 1155.28H805.55C810.854 1155.28 815.941 1153.17 819.692 1149.42C823.443 1145.67 825.55 1140.58 825.55 1135.28C825.55 1129.98 823.443 1124.89 819.692 1121.14C815.941 1117.39 810.854 1115.28 805.55 1115.28H494.71C489.406 1115.28 484.319 1117.39 480.568 1121.14C476.817 1124.89 474.71 1129.98 474.71 1135.28Z"
										fill="white"
										stroke="#DDAB3C"
										stroke-width="5"
										stroke-miterlimit="10"
									/>
									<path
										id="Vector_2"
										d="M585.89 1187.5H702.89C706.645 1187.5 710.247 1186.01 712.903 1183.35C715.558 1180.7 717.05 1177.1 717.05 1173.34V1155.28H571.74V1173.35C571.743 1177.1 573.234 1180.7 575.887 1183.35C578.54 1186.01 582.138 1187.5 585.89 1187.5V1187.5Z"
										fill="white"
										stroke="#DDAB3C"
										stroke-width="5"
										stroke-miterlimit="10"
									/>
									<path
										id="Vector_3"
										d="M659.72 1432.58H629.11V1187.5H659.72V1432.58Z"
										fill="white"
										stroke="#DDAB3C"
										stroke-width="5"
										stroke-miterlimit="10"
									/>
									<path
										id="Vector_4"
										d="M661.75 1495.8H627.06C620.3 1495.8 614.82 1490.32 614.82 1483.56V1444.82C614.82 1438.06 620.3 1432.58 627.06 1432.58H661.75C668.51 1432.58 673.99 1438.06 673.99 1444.82V1483.56C673.99 1490.32 668.51 1495.8 661.75 1495.8Z"
										fill="white"
										stroke="#DDAB3C"
										stroke-width="5"
										stroke-miterlimit="10"
									/>
									<path
										id="Vector_5"
										d="M508.94 1552.74C508.94 1537.64 514.939 1523.16 525.617 1512.48C536.296 1501.8 550.779 1495.8 565.88 1495.8H722.94C738.041 1495.8 752.524 1501.8 763.203 1512.48C773.881 1523.16 779.88 1537.64 779.88 1552.74"
										stroke="#DDAB3C"
										stroke-width="5"
										stroke-miterlimit="10"
									/>
									<path
										id="Vector_6"
										d="M507.91 1582.32C495.312 1582.32 485.1 1572.11 485.1 1559.51C485.1 1546.91 495.312 1536.7 507.91 1536.7C520.508 1536.7 530.72 1546.91 530.72 1559.51C530.72 1572.11 520.508 1582.32 507.91 1582.32Z"
										fill="white"
										stroke="#DDAB3C"
										stroke-width="5"
										stroke-miterlimit="10"
									/>
									<path
										id="Vector_7"
										d="M781.32 1582.32C768.722 1582.32 758.51 1572.11 758.51 1559.51C758.51 1546.91 768.722 1536.7 781.32 1536.7C793.918 1536.7 804.13 1546.91 804.13 1559.51C804.13 1572.11 793.918 1582.32 781.32 1582.32Z"
										fill="white"
										stroke="#DDAB3C"
										stroke-width="5"
										stroke-miterlimit="10"
									/>
									<path
										id="Vector_8"
										d="M643.59 1582.32C630.992 1582.32 620.78 1572.11 620.78 1559.51C620.78 1546.91 630.992 1536.7 643.59 1536.7C656.188 1536.7 666.4 1546.91 666.4 1559.51C666.4 1572.11 656.188 1582.32 643.59 1582.32Z"
										fill="white"
										stroke="#DDAB3C"
										stroke-width="5"
										stroke-miterlimit="10"
									/>
								</g>
								<g id="WOMAN">
									<g id="LEGS">
										<g id="LEFT LEG">
											<path
												id="Vector_9"
												d="M940.94 1463.39C951.12 1487.54 960.39 1507.34 967.46 1520.33C972.72 1529.99 981.88 1586.33 1030.04 1563.63C1031.74 1562.83 1011.04 1557.49 1004.51 1510.88C1002.22 1494.54 1000.13 1478.78 998.22 1463.6C979.38 1464.88 955.48 1464.1 940.94 1463.39Z"
												fill="#FFCFC9"
											/>
											<path
												id="SHOE"
												d="M951.35 1586.33C951.35 1586.33 931.96 1519.23 957.89 1508.99C966.17 1505.72 976.21 1513.99 978.89 1542.23C981.24 1566.92 1017.31 1570.75 1020.71 1554.79C1020.71 1554.79 1064.93 1563.79 1064.93 1586.33H951.35Z"
												fill="#294C75"
											/>
											<path
												id="Vector_10"
												d="M927.85 1462.63C927.85 1462.63 981.41 1466.46 1009.58 1462.45C1009.58 1462.45 1046.98 1072.3 1041.39 1032.16C1034.66 983.87 873.9 941.07 790.79 927.5L775.48 858.16C775.48 858.16 663.04 856.16 599.55 856.16C599.55 856.16 531.55 977.16 538.55 1057.16C545.55 1137.16 692.17 1119 887.98 1119C887.98 1119 828.28 1239.51 927.85 1462.63Z"
												fill="#1E7B9F"
											/>
										</g>
										<g id="RIGHT LEG">
											<path
												id="Vector_11"
												d="M764.69 1465.29C779.587 1466.61 794.576 1466.49 809.45 1464.93C804.45 1484.38 799.83 1502.93 796.16 1519.5C793.79 1530.14 809.02 1544.5 813.36 1554.95L813.02 1554.79C793.02 1551.6 766.19 1570.54 768.95 1540.98C771.71 1511.42 767.47 1504.05 764.69 1465.29Z"
												fill="#FFCFC9"
											/>
											<path
												id="SHOE_2"
												d="M769.55 1519.23C762.67 1519.23 751.49 1531.67 752.6 1551.59C753.238 1563.48 755.7 1575.19 759.9 1586.33H837.23C835.87 1564.88 813.02 1554.79 813.02 1554.79C793.02 1551.6 767.19 1570.54 769.95 1540.98L769.55 1519.23Z"
												fill="#294C75"
											/>
											<path
												id="Vector_12"
												d="M709.01 965C709.01 965 879.44 995.8 898.8 1046.85C918.16 1097.9 827.85 1462.6 827.85 1462.6C775.49 1470.6 748.63 1462.6 748.63 1462.6C694.17 1212.84 757.98 1130 757.98 1130C631.07 1124.61 550.98 1136.65 538.54 1057.22C534.57 1032 562.93 935.53 709.01 965Z"
												fill="#1E7B9F"
											/>
											<path
												id="Vector_13"
												d="M712.01 965C712.01 965 882.44 995.8 901.8 1046.85C921.16 1097.9 830.85 1462.6 830.85 1462.6"
												stroke="#1F3147"
												stroke-width="5"
												stroke-miterlimit="10"
											/>
										</g>
									</g>
									<g id="LEFT HAND">
										<path
											id="Vector_14"
											d="M749.48 801.58C739.35 778.68 784.32 748.58 830.34 760.58C830.34 760.58 814.82 575.9 778.45 552.63C743.15 530.05 734.65 636.4 733.45 662.37C733.49 662.37 726.96 762.84 749.48 801.58Z"
											fill="#DBEAF0"
										/>
										<path
											id="Vector_15"
											d="M753.44 807.15C731.15 787.15 777.24 746.45 830.34 760.58C863.56 765.74 992.7 793.27 1004.84 799.58C1016.7 804.87 1050.95 822.1 1055.31 827.33C1060.81 833.93 1061.65 851.39 1061.31 853.69C1060.97 855.99 1056.46 867.96 1056.46 867.96C1056.46 867.96 1047.81 867.07 1048.91 857.66C1050.23 846.47 1040.27 843 1033.61 840C1030.83 838.76 1023.61 839.27 1022.34 843.48C1021.07 847.69 1031.6 852.79 1033.34 855.84C1035.93 860.46 1032.93 865.63 1032.87 865.74C1032.81 865.85 1016.81 857.12 1013.72 855.48C1010.88 853.97 997.53 838.39 991.87 824.66C991.83 824.65 792.57 842.31 753.44 807.15Z"
											fill="#FFC0B7"
										/>
									</g>
									<g id="HAIR">
										<path
											id="Vector_16"
											d="M778.17 453.26C781.92 444.15 797.46 397.01 772.28 376.65C747.1 356.29 713.89 335.4 673.71 338.65C633.53 341.9 624 387 599.56 404C578 418.997 566.25 414.9 540 438.5C513.75 462.1 523.76 486.75 497.01 506.58C470.26 526.41 456.3 512.96 429.5 548C398.78 588.18 421.705 617.59 381.5 643.5C336.5 672.5 324 711 331.61 767.22H582.79C743.17 742.44 778.17 453.26 778.17 453.26Z"
											fill="#DDAB3C"
										/>
										<path
											id="Vector_17"
											d="M760.86 373.83C736.38 352.14 707.92 337.71 667.66 339.68C627.4 341.65 613.5 378 588.88 394.54C569 407.896 556.53 403.69 529.5 427C502.47 450.31 508.76 479.26 481.31 498.69C453.86 518.12 444.46 507.97 416.5 543C384.43 583.17 406.085 602.128 368 634.5C328 668.5 307.5 706 313 767.5"
											stroke="#DDAB3C"
											stroke-width="5"
											stroke-miterlimit="10"
										/>
									</g>
									<g id="BLOUSE">
										<path
											id="Vector_18"
											d="M776.24 857.46C776.24 857.46 790.48 741.4 798.01 735.32C814.92 721.66 828.37 702.07 821.32 675.02C817.32 659.75 785.17 558.53 778.32 552.1C771.47 545.67 726.52 529.6 726.52 529.6H599.52C585.06 534.42 539.66 550.75 528.81 567.37C475.81 648.53 458.86 778.71 458.86 778.71H524.76C524.76 778.71 556.48 707.52 567.76 706.71C579.04 705.9 600.17 856.46 600.17 856.46C600.17 856.46 672.42 876.51 776.24 857.46Z"
											fill="#DBEAF0"
										/>
										<path
											id="Vector_19"
											d="M777.24 857.46C777.24 857.46 791.48 741.4 799.01 735.32C815.92 721.66 830.37 701.07 823.32 674.02"
											stroke="#1F3147"
											stroke-width="5"
											stroke-miterlimit="10"
										/>
										<path
											id="Vector_20"
											d="M687.17 543.7C687.17 543.7 745.67 655.32 731.17 867.46"
											stroke="white"
											stroke-width="5"
											stroke-miterlimit="10"
										/>
										<path
											id="Vector_21"
											d="M694.16 638.2C689.902 638.2 686.45 634.748 686.45 630.49C686.45 626.232 689.902 622.78 694.16 622.78C698.418 622.78 701.87 626.232 701.87 630.49C701.87 634.748 698.418 638.2 694.16 638.2Z"
											stroke="white"
											stroke-width="5"
											stroke-miterlimit="10"
										/>
										<path
											id="Vector_22"
											d="M704.73 694.74C700.472 694.74 697.02 691.288 697.02 687.03C697.02 682.772 700.472 679.32 704.73 679.32C708.988 679.32 712.44 682.772 712.44 687.03C712.44 691.288 708.988 694.74 704.73 694.74Z"
											stroke="white"
											stroke-width="5"
											stroke-miterlimit="10"
										/>
										<path
											id="Vector_23"
											d="M710.38 751.48C706.122 751.48 702.67 748.028 702.67 743.77C702.67 739.512 706.122 736.06 710.38 736.06C714.638 736.06 718.09 739.512 718.09 743.77C718.09 748.028 714.638 751.48 710.38 751.48Z"
											stroke="white"
											stroke-width="5"
											stroke-miterlimit="10"
										/>
									</g>
									<g id="R_HAND">
										<path
											id="Vector_24"
											d="M466.17 810.36C498.8 844.67 709.76 798.79 709.76 798.79C709.76 798.79 720.76 816.17 733.64 816.79C746.52 817.41 758.71 812.86 765.25 818.38C771.79 823.9 778.39 833.51 783.51 834.5C789.589 835.743 795.828 836.007 801.99 835.28C799.802 829.247 797.285 823.338 794.45 817.58C798.34 820.73 803.17 826.51 806 828.92C807.178 830.016 808.69 830.684 810.294 830.816C811.897 830.948 813.498 830.538 814.84 829.65C814.84 829.65 806.39 816.75 803.06 813.3C799.73 809.85 789.52 801.19 783.86 799.22C777.17 796.36 738 777.17 718.45 773.99C697.72 770.63 529.84 759.33 529.84 759.33C469.12 759.29 438.77 781.56 466.17 810.36Z"
											fill="#FFCFC9"
										/>
										<path
											id="Vector_25"
											d="M795.68 833.27C795.68 833.27 783.56 812.2 779.5 809.97C772.35 806.03 763.02 800.85 763.02 800.85"
											stroke="#FFB0AF"
											stroke-width="3"
											stroke-miterlimit="10"
											stroke-linecap="round"
										/>
										<path
											id="Vector_26"
											d="M787.3 832.89C787.3 832.89 776.59 815.56 772.53 813.33C765.38 809.39 756.05 804.21 756.05 804.21"
											stroke="#FFB0AF"
											stroke-width="3"
											stroke-miterlimit="10"
											stroke-linecap="round"
										/>
									</g>
									<g id="NECK">
										<path
											id="Vector_27"
											d="M675.09 451.69C675.09 451.69 657.42 507.1 599.56 529.6C599.56 529.6 680.72 564.96 726.56 529.6V453.29L675.09 451.69Z"
											fill="#FFCFC9"
										/>
										<path
											id="Vector_28"
											d="M714.84 536.81C718.972 534.816 722.886 532.4 726.52 529.6V453.29L675.09 451.69C675.09 451.69 674.59 453.25 673.49 455.96C668.24 479.35 682.44 527.88 714.84 536.81Z"
											fill="#FFC0B7"
										/>
									</g>
									<path
										id="HEAD"
										d="M676.44 460.22C676.97 478.44 711.26 521.3 730.01 521.3C745.55 521.3 774.56 474.43 778.22 453.3C781.97 431.56 778.17 393 747.17 392.46C728.01 392.13 718.17 410.13 712.42 431C705.42 456.19 683.94 450.54 683.94 450.54C683.94 450.54 677.79 436.54 669.22 443.54C660.65 450.54 676.44 460.22 676.44 460.22Z"
										fill="#FFCFC9"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g id="character 14 1" clip-path="url(#clip1)">
			<g id="Layer 2_2">
				<g id="&#208;&#161;&#208;&#187;&#208;&#190;&#208;&#185; 1_2">
					<g id="2_2">
						<g id="TABLE">
							<path
								id="Vector_29"
								d="M1174.55 1586.33L1124.55 897.5L1074.55 1587.78"
								stroke="#DDAB3C"
								stroke-width="5"
								stroke-miterlimit="10"
							/>
							<path
								id="Vector_30"
								d="M1178.94 897.5H520.61C516.497 897.5 512.553 895.868 509.643 892.961C506.733 890.055 505.095 886.113 505.09 882C505.09 877.884 506.725 873.936 509.636 871.026C512.546 868.115 516.494 866.48 520.61 866.48H1178.94V897.5Z"
								fill="white"
							/>
							<path
								id="Vector_31"
								d="M1178.94 897.5H520.61C516.497 897.5 512.553 895.868 509.643 892.961C506.733 890.055 505.095 886.113 505.09 882V882C505.09 877.884 506.725 873.936 509.636 871.026C512.546 868.115 516.494 866.48 520.61 866.48H1178.94"
								stroke="#DDAB3C"
								stroke-width="5"
								stroke-miterlimit="10"
							/>
						</g>
						<g id="LAPTOP">
							<path
								id="Vector_32"
								d="M918.17 632.32H1183.84C1186.77 632.322 1189.65 633.031 1192.25 634.387C1194.84 635.742 1197.07 637.703 1198.74 640.104C1200.42 642.505 1201.49 645.275 1201.86 648.178C1202.24 651.081 1201.91 654.032 1200.9 656.78L1133.9 838.78H822.8L892.1 650.51C894.062 645.175 897.614 640.57 902.276 637.318C906.938 634.065 912.485 632.32 918.17 632.32V632.32Z"
								stroke="black"
								stroke-miterlimit="10"
							/>
							<path
								id="Vector_33"
								d="M915.78 632.32H1182.43C1194.76 632.32 1203.32 645.39 1199.06 657.72L1141.06 826.13C1139.9 829.75 1137.64 832.917 1134.59 835.186C1131.54 837.455 1127.86 838.712 1124.06 838.78H825.78L890.35 651.22C894.27 639.86 904.42 632.32 915.78 632.32Z"
								fill="white"
							/>
							<path
								id="Vector_34"
								d="M926.78 644.93H1193.43C1196.29 644.929 1199.1 645.619 1201.63 646.939C1204.16 648.26 1206.34 650.173 1207.97 652.515C1209.6 654.857 1210.65 657.559 1211.01 660.391C1211.37 663.223 1211.05 666.101 1210.06 668.78L1152.06 826.9C1150.78 830.383 1148.47 833.39 1145.43 835.514C1142.39 837.639 1138.77 838.779 1135.06 838.78H836.78L901.35 662.67C903.265 657.467 906.73 652.976 911.277 649.804C915.824 646.632 921.235 644.93 926.78 644.93Z"
								fill="#DCE4EA"
							/>
							<path
								id="Vector_35"
								d="M1134.52 838.78H625.66C618.565 838.78 611.761 841.598 606.745 846.615C601.728 851.631 598.91 858.435 598.91 865.53H1107.77C1114.86 865.53 1121.67 862.712 1126.69 857.695C1131.7 852.679 1134.52 845.875 1134.52 838.78Z"
								fill="white"
								stroke="#4A5568"
								stroke-width="6"
								stroke-miterlimit="10"
							/>
							<path
								id="Vector_36"
								d="M918.17 632.32H1183.84C1186.77 632.322 1189.65 633.031 1192.25 634.387C1194.84 635.742 1197.07 637.703 1198.74 640.104C1200.42 642.505 1201.49 645.275 1201.86 648.178C1202.24 651.081 1201.91 654.032 1200.9 656.78L1133.9 838.78H822.8L892.1 650.51C894.062 645.175 897.614 640.57 902.276 637.318C906.938 634.065 912.485 632.32 918.17 632.32V632.32Z"
								stroke="#4A5568"
								stroke-width="5"
								stroke-miterlimit="10"
							/>
							<path
								id="Vector_37"
								d="M1015.96 764.462C1009.67 757.472 1011.56 745.512 1020.18 737.747C1028.8 729.983 1040.9 729.355 1047.19 736.345C1053.48 743.334 1051.59 755.295 1042.97 763.059C1034.35 770.823 1022.26 771.451 1015.96 764.462Z"
								fill="#DDAB3C"
							/>
							<path
								id="Vector_38"
								d="M1009.85 758.095C1003.56 751.106 1005.45 739.145 1014.07 731.381C1022.69 723.617 1034.78 722.989 1041.08 729.978C1047.37 736.968 1045.48 748.928 1036.86 756.693C1028.24 764.457 1016.14 765.085 1009.85 758.095Z"
								stroke="#4A5568"
								stroke-width="5"
								stroke-miterlimit="10"
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</g>
	<defs>
		<clipPath id="clip0">
			<rect
				width="909.17"
				height="1250.96"
				fill="white"
				transform="matrix(-1 0 0 1 1211.17 337)"
			/>
		</clipPath>
		<clipPath id="clip1">
			<rect
				width="909.17"
				height="1250.96"
				fill="white"
				transform="matrix(-1 0 0 1 1211.17 337)"
			/>
		</clipPath>
	</defs>
</svg>

<style>
	#R_HAND {
		animation: hand 4s infinite ease-in-out;
		transform-origin: left top;
		transform-box: fill-box;
	}

	@keyframes hand {
		0% {
			transform: rotateZ(0deg);
		}

		4% {
			transform: rotateZ(1deg);
		}

		11% {
			transform: rotateZ(-2deg);
		}

		22% {
			transform: rotateZ(1deg);
		}

		25% {
			transform: rotateZ(-2deg);
		}

		35% {
			transform: rotateZ(1deg);
		}

		42% {
			transform: rotateZ(-2deg);
		}

		45% {
			transform: rotateZ(0deg);
		}
	}
</style>
